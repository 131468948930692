body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.footer {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 32px; }
  @media (min-width: 768px) {
    .footer {
      text-align: left;
      padding-top: 60px;
      max-width: 650px;
      box-sizing: content-box; } }
  .footer__how-it-works {
    text-align: center; }
    .footer__how-it-works--heading {
      font-weight: 500;
      margin-top: 35px; }
    .footer__how-it-works--description {
      margin-top: 15px; }
    .footer__how-it-works--toggle-icon {
      color: #fff100;
      background-color: #008aae;
      border-radius: 12px;
      margin-top: 35px;
      cursor: pointer; }
    .footer__how-it-works .open {
      height: auto; }
    .footer__how-it-works .close {
      height: 200px;
      overflow: hidden;
      position: relative; }
    .footer__how-it-works .close:after {
      height: 50%;
      width: 100%;
      content: "";
      background: linear-gradient(to top, white 20%, rgba(255, 255, 255, 0) 80%);
      position: absolute;
      bottom: 0;
      left: 0; }
  @media (min-width: 768px) {
    .footer__columns-top {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      padding-top: 70px;
      border-top: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC; } }
  @media (min-width: 768px) {
    .footer__columns-bottom {
      display: flex;
      flex-direction: row-reverse; } }
  @media (min-width: 768px) {
    .footer__column--evryplace, .footer__column--help, .footer__column--more {
      width: 25%; } }
  @media (min-width: 1280px) {
    .footer__column--evryplace, .footer__column--help, .footer__column--more {
      width: 21%; } }
  .footer__column--evryplace {
    display: none; }
  @media (min-width: 768px) {
    .footer__column {
      display: block; } }
  .footer__column--help {
    margin-top: 43px;
    margin-bottom: 43px;
    border-top: 1px solid #CCCCCC;
    padding-top: 43px; }
    @media (min-width: 768px) {
      .footer__column--help {
        padding-top: 0;
        border: none;
        margin-top: 0; } }
    @media (min-width: 1280px) {
      .footer__column--help {
        margin-bottom: 0; } }
  .footer__column--more {
    margin-bottom: 31px; }
    @media (min-width: 768px) {
      .footer__column--more {
        margin-bottom: 0; } }
  .footer__column--logos {
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 28px 0;
    margin-bottom: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .footer__column--logos > a {
      display: block; }
    @media (min-width: 768px) {
      .footer__column--logos {
        border-top: none;
        border-bottom: none;
        width: 66%;
        text-align: right;
        padding: 23px 0; } }
    @media (min-width: 1280px) {
      .footer__column--logos {
        padding: 32px 0; } }
  @media (min-width: 768px) {
    .footer__column--terms {
      width: 50%;
      display: flex;
      padding-top: 25px; } }
  .footer__column-heading {
    color: #006783;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .footer__column-heading {
        font-size: 22px;
        margin-bottom: 22px; } }
    @media (min-width: 1280px) {
      .footer__column-heading {
        font-size: 16px;
        margin-bottom: 29px; } }
  .footer__column-links > a {
    color: #4D4D4D;
    text-transform: capitalize;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px; }
    .footer__column-links > a.social {
      margin-top: 44px; }
    @media (min-width: 768px) {
      .footer__column-links > a {
        font-size: 16px;
        margin-bottom: 13px; }
        .footer__column-links > a.social {
          margin-top: 44px;
          margin-top: 72px; } }
    @media (min-width: 1280px) {
      .footer__column-links > a {
        font-size: 13px;
        margin-bottom: 7px; }
        .footer__column-links > a.social {
          margin-top: 28px;
          margin-top: 48px; } }
    .footer__column-links > a:hover {
      color: #1F97BC; }
  .footer__img {
    width: 70px;
    margin-left: 15px;
    margin-right: 15px; }
    @media (min-width: 768px) {
      .footer__img {
        width: 103px; }
        .footer__img:last-child {
          margin-right: 0; } }
    @media (min-width: 1280px) {
      .footer__img {
        width: 75px; } }
  .footer__logo {
    display: none; }
    @media (min-width: 768px) {
      .footer__logo {
        display: block;
        width: 64px;
        height: 28px;
        margin-right: 19px; } }
  .footer__copyright {
    font-size: 10px; }
    @media (min-width: 768px) {
      .footer__copyright {
        font-size: 15px; } }
  .footer__link {
    color: #4D4D4D; }
    .footer__link:hover {
      color: #1F97BC; }
  .footer__gottotop {
    display: none;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    position: absolute;
    right: 5%;
    bottom: 340px;
    border-radius: 50%;
    box-shadow: 0 0 11px 0px rgba(0, 0, 0, 0.5); }
    @media (min-width: 1280px) {
      .footer__gottotop {
        display: flex; } }
    .footer__gottotop > svg {
      width: 13px;
      height: 13px; }
    .footer__gottotop > div {
      position: absolute;
      color: gray;
      bottom: -20px;
      font-size: 12px;
      width: 80px;
      text-align: center;
      text-align: #666666; }
