body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.coronavirus-modal h4 {
  margin: 20px 0;
  font-size: 14px;
  color: #666666; }

.coronavirus-modal .modal__body {
  width: auto;
  padding: 30px;
  display: flex; }

.coronavirus-modal .coronavirus-modal__image-wrapper {
  display: none; }

.coronavirus-modal a {
  background: #008B87;
  color: #fff !important;
  margin-top: 20px;
  text-decoration: none !important;
  font-size: 16px; }
  .coronavirus-modal a:hover {
    color: #008B87 !important; }

.coronavirus-modal .bold {
  font-weight: bold; }

@media (min-width: 768px) {
  .coronavirus-modal .coronavirus-modal__image-wrapper {
    width: 380px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .coronavirus-modal .coronavirus-modal__image-wrapper img {
      width: 100%;
      height: auto; }
  .coronavirus-modal .coronavirus-modal-wrapper {
    width: 510px;
    padding-left: 30px;
    border-left: 1px solid #b3b3b3; } }
