body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.mobile-menu {
  width: 250px;
  height: 100vh;
  position: fixed;
  overflow-x: auto;
  top: 0;
  left: 0;
  background: #4c4c4c;
  z-index: 5000;
  padding: 25px 0;
  -webkit-transform: translateX(-250px);
  -ms-transform: translateX(-250px);
  transform: translateX(-250px);
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }
  .mobile-menu--active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
    @media (min-width: 768px) {
      .mobile-menu--active {
        width: 560px; } }
  .mobile-menu__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 55px;
    padding-right: 55px; }
    @media (max-width: 767px) {
      .mobile-menu__header {
        flex-direction: column;
        padding-left: 25px;
        padding-right: 25px; } }
  @media (max-width: 767px) {
    .mobile-menu__login-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; } }
  .mobile-menu__login-button {
    box-sizing: border-box;
    width: auto;
    border-radius: 7px;
    white-space: nowrap;
    font-family: "brandon", sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 10px;
    margin-left: 10px !important;
    letter-spacing: 2px; }
    .mobile-menu__login-button:hover {
      background-color: #FFF100;
      color: #006783; }
  @media (max-width: 767px) {
    .mobile-menu__login-button.no-sides-margin {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  .mobile-menu__signup-button.button {
    margin: 0;
    border: 3px solid #FFF100; }
  .mobile-menu .login-btn {
    font-size: 18px;
    align-self: auto;
    padding: 6px 14px; }
    @media (max-width: 767px) {
      .mobile-menu .login-btn {
        border: 1px solid #FFF100;
        font-size: 13px;
        padding: 5px 17px;
        margin: 10px 5px 5px 0; } }
  .mobile-menu .login-btn.no-sides-margin {
    margin: 10px 0 0 0; }
  .mobile-menu__logo {
    text-align: center; }
    .mobile-menu__logo svg {
      fill: white;
      width: 50px;
      height: 23px; }
      @media (min-width: 768px) {
        .mobile-menu__logo svg {
          width: 77px;
          height: 35px; } }
  .mobile-menu__links {
    padding: 20px 0;
    margin-top: 20px;
    position: relative; }
    .mobile-menu__links::before {
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: 80%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #cfcfcf; }
  .mobile-menu__item {
    position: relative; }
  .mobile-menu__link {
    text-transform: capitalize;
    color: #FFFFFF;
    font-size: 16px;
    padding: 10px 25px;
    display: block; }
    @media (min-width: 768px) {
      .mobile-menu__link {
        font-size: 18px;
        padding: 15px 55px; } }
    .mobile-menu__link:hover {
      background-color: #CBCBCB;
      color: #FFF100; }
    .mobile-menu__link--second {
      color: #989898; }
  .mobile-menu__close-btn--active {
    width: 20px;
    height: 20px;
    position: fixed;
    top: 30px;
    left: 265px;
    z-index: 99999;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    pointer-events: all; }
    @media (min-width: 768px) {
      .mobile-menu__close-btn--active {
        left: 580px;
        width: 30px;
        height: 30px; } }
    .mobile-menu__close-btn--active:focus {
      outline: none; }
    .mobile-menu__close-btn--active span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #FFF100;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out; }
    .mobile-menu__close-btn--active span:nth-child(1) {
      top: 10px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg); }
    .mobile-menu__close-btn--active span:nth-child(2) {
      opacity: 0;
      left: -60px; }
    .mobile-menu__close-btn--active span:nth-child(3) {
      top: 10px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg); }
