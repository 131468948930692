#get-started {
  background-color: #000;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%; }

#get-started h1, #get-started h2, #get-started h3, #get-started h4 {
  margin-bottom: 30px;
  color: #fff; }

#get-started span {
  display: block;
  width: 100%; }

#get-started p {
  color: #fff;
  margin-bottom: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300; }

#get-started .btn {
  max-width: 190px;
  display: inline-block;
  border: 2px solid #ffff41;
  width: 190px;
  height: 45px;
  line-height: 42px;
  border-radius: 20px;
  transition: all .15s ease-in-out;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  color: #ffff41; }

#get-started .btn:hover {
  background-color: #ffff41;
  color: #000; }

#get-started .faq-btn {
  max-width: 245px;
  display: inline-block;
  border: 2px solid #ffff41;
  width: 245px;
  height: 45px;
  line-height: 42px;
  border-radius: 20px;
  transition: all .15s ease-in-out;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  color: #ffff41; }

#get-started .faq-btn:hover {
  background-color: #ffff41;
  color: #000; }

#get-started p.conv {
  margin-bottom: 0; }

#get-started a.yellow {
  color: #ffff41;
  letter-spacing: 1.1px;
  position: relative;
  display: inline-block; }

#get-started a.yellow:after {
  content: "";
  width: 100%;
  height: 1px;
  bottom: 1px;
  background: #ffff41;
  position: absolute;
  left: 0;
  transition: all .15s ease-in-out; }

#get-started a.yellow:hover:after {
  width: 0%;
  left: 50%; }

@media only screen and (max-width: 48em) {
  #get-started {
    padding-top: 60px;
    padding-bottom: 60px; }
  #get-started .btn {
    margin-bottom: 0; }
  #get-started .faq-btn {
    font-size: 12px; }
  #get-started a.yellow, #get-started p.conv {
    font-size: 18px;
    letter-spacing: 0; } }
