.webvr_QR_modal {
  display: none;
  font-weight: 100;
  overflow: auto; }

.webvr_QR_modal.visible {
  display: flex;
  z-index: 9999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.666);
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  align-items: center;
  justify-content: center; }

.webvr_QR_modal .content {
  margin: 1em;
  text-align: center;
  position: relative; }

.webvr_QR_modal .control {
  width: 30px;
  height: 30px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer; }

.webvr_QR_modal .title {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 80px;
  text-align: center; }

.webvr_QR_modal .close {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
  margin-left: 30px;
  fill: white;
  position: absolute;
  top: 20px;
  right: 20px; }

.webvr_QR_modal .text {
  margin: 0 auto;
  position: relative;
  font-size: 20px;
  color: #fff; }

.webvr_QR_modal .more {
  margin: 0 auto;
  margin-top: 38px;
  text-transform: uppercase;
  color: #FFF100;
  font-size: 13px; }
  .webvr_QR_modal .more a {
    color: #FFF100; }

.link-container {
  width: 360px;
  position: relative;
  margin: 0 auto; }

.webvr_QR_modal .link {
  width: 360px;
  border-radius: 20px;
  border: none;
  margin-top: 24px;
  background-color: white;
  height: 46px;
  padding-left: 20px;
  padding-right: 5px;
  color: black; }

.vr-image {
  max-width: 380px;
  height: auto; }

@media (max-width: 768px) {
  .webvr_QR_modal .close {
    top: 70px; }
  .webvr_QR_modal.visible {
    padding-top: 85px;
    justify-content: flex-start; }
    .webvr_QR_modal.visible .content {
      padding-top: 50px; } }

@media only screen and (max-width: 400px) {
  .webvr_QR_modal .title {
    font-size: 20px;
    max-width: 80vw; }
  .vr-image {
    max-width: 80vw; }
  .webvr_QR_modal .text {
    max-width: 80vw;
    font-size: 15px; }
  .webvr_QR_modal .link {
    max-width: 95vw; }
  .link-container {
    max-width: 95vw; }
  .webvr_QR_modal.visible .content {
    padding-top: 0; }
    .webvr_QR_modal.visible .content .title {
      margin-bottom: 20px; } }
