body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.contact-modal h4 {
  margin: 20px 0;
  font-size: 14px;
  color: #666666; }

.contact-modal .modal__body {
  width: auto;
  padding: 30px;
  display: flex; }

.contact-modal .contact-modal__image-wrapper {
  display: none; }

.contact-modal .contact-modal__form-wrapper {
  width: 100%; }

.contact-modal .message-text-field {
  /* Placeholder */ }
  .contact-modal .message-text-field hr {
    bottom: 22px !important; }
  .contact-modal .message-text-field > div:first-child {
    top: 12px !important;
    bottom: auto !important; }

.contact-modal button {
  background: #008B87;
  color: #fff; }

@media (min-width: 768px) {
  .contact-modal .contact-modal__image-wrapper {
    width: 380px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .contact-modal .contact-modal__image-wrapper img {
      width: 100%;
      height: auto; }
  .contact-modal .contact-modal__form-wrapper {
    width: 510px;
    padding-left: 30px;
    border-left: 1px solid #b3b3b3; } }
