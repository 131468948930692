body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.logotype-set {
  background-color: #F1F1F1; }
  .logotype-set__wrapper {
    margin: 0 auto;
    width: 100%;
    height: 40px; }
    @media (min-width: 768px) {
      .logotype-set__wrapper {
        padding: 0 66px; } }
    @media screen and (min-width: 660px) {
      .logotype-set__wrapper {
        max-width: 660px;
        height: 60px;
        padding: 0; } }
  .logotype-set__text {
    display: none;
    font-size: 12px;
    font-weight: 700;
    color: #666666;
    letter-spacing: 1px; }
    @media (min-width: 1280px) {
      .logotype-set__text {
        display: block; } }
  .logotype-set__logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%; }
    .logotype-set__logos > svg {
      fill: #666666;
      width: 12%;
      max-height: 35%; }
      @media (min-width: 768px) {
        .logotype-set__logos > svg {
          width: 11.5%; } }
  .logotype-set__logo {
    display: block;
    width: 30%;
    height: auto;
    margin: 0 auto !important; }
  .logotype-set__link svg, .logotype-set__link img {
    width: 60%; }
    @media (min-width: 768px) {
      .logotype-set__link svg, .logotype-set__link img {
        width: 40%; } }
  .logotype-set__link path, .logotype-set__link polygon {
    fill: #4D4D4D; }
  .logotype-set__link:hover path, .logotype-set__link:hover polygon {
    fill: #008B87; }
  .logotype-set__logos--explore {
    height: 65px !important; }
