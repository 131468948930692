body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10005;
  text-align: center; }
  .modal__window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 70px;
    min-width: 300px;
    color: #666666;
    background: #fff;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.7);
    border-radius: 2px; }
  .modal__close {
    position: absolute;
    height: 30px !important;
    width: 30px !important;
    right: 20px;
    top: 20px;
    cursor: pointer; }
    .modal__close:hover {
      transform: rotate(90deg);
      transition: all ease-in 0.2s; }
  .modal__overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1000; }
  .modal__heading {
    color: #008aae;
    text-align: center;
    font-size: 20px;
    margin-bottom: 15px; }
  .modal__body {
    padding: 30px 50px;
    width: 100%; }
  .modal .status {
    transition: all 0.2s ease-in-out;
    pointer-events: none; }
    .modal .status--closed {
      opacity: 0; }
    .modal .status--opened {
      opacity: 1; }
    .modal .status svg {
      width: 20px;
      height: 17px;
      display: inline-block;
      margin-top: 0px;
      margin-right: 10px;
      margin-left: 6px; }
  .modal h3 {
    margin: 10px 0 80px 0;
    font-weight: 800; }
  .modal a {
    color: #333333;
    text-decoration: underline; }
  .modal.modal--no-window .modal__window {
    top: 0;
    left: 0;
    transform: none;
    box-shadow: none;
    background: none;
    width: 100%;
    height: 100vh;
    max-height: none; }
  .modal.modal--title .modal__window {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    transform: none;
    max-height: none;
    border-radius: 2px;
    overflow: hidden;
    padding: 0;
    background: #fff; }
    .modal.modal--title .modal__window .modal__body {
      padding: 0;
      width: 100%; }
    .modal.modal--title .modal__window .modal-title {
      background: #2E2E2E;
      padding: 22px;
      font-size: 20px;
      line-height: 23px;
      color: #fff;
      text-align: left;
      margin-top: -1px; }
    .modal.modal--title .modal__window .modal-content {
      color: #828282;
      padding: 0 40px;
      min-height: 170px; }
      .modal.modal--title .modal__window .modal-content form {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: none; }
        .modal.modal--title .modal__window .modal-content form label {
          padding-top: 18px;
          padding-bottom: 10px;
          font-size: 24px;
          color: #BDBDBD; }
        .modal.modal--title .modal__window .modal-content form input[type="text"] {
          box-shadow: none;
          font-size: 26px;
          text-align: center;
          color: #2E2E2E;
          border: none;
          border-bottom: 2px solid #bdbdbd;
          max-width: 190px;
          margin: 0 auto 44px; }
      .modal.modal--title .modal__window .modal-content .error {
        color: #f00; }
      .modal.modal--title .modal__window .modal-content .success {
        color: #27AE60; }
      .modal.modal--title .modal__window .modal-content .input-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 153px; }
        .modal.modal--title .modal__window .modal-content .input-wrapper.higher {
          min-height: 220px; }
      .modal.modal--title .modal__window .modal-content .cta-button {
        flex-grow: 0;
        background: #FFF100;
        border-radius: 30px;
        padding: 14px 0;
        width: 220px;
        border: none;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 25px; }
    .modal.modal--title .modal__window .modal-secondary-content {
      background: #F2F2F2; }
  .modal.modal--title .modal__close {
    color: #fff; }
  .modal .hash-title {
    color: #5b5b5b; }
  .modal .hash-value {
    padding-top: 10px; }
  .modal .link {
    margin-top: 4px;
    padding: 0; }
  .modal .links-textfield {
    border-bottom: 1px solid #adadad;
    width: 200px;
    margin-bottom: 4px; }
    .modal .links-textfield div input {
      padding: 4px 0 2px 0; }
  .modal .send-invite-btn {
    background-color: #adadad;
    border-radius: 22px;
    color: white !important;
    padding: 13px 16px; }
  @media (min-width: 768px) {
    .modal__window {
      max-height: 85vh;
      width: auto;
      height: auto; }
    .modal__body {
      padding: 60px 100px;
      width: 500px; }
    .modal.modal--title .modal__window {
      width: 520px;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); } }
  @media (min-width: 1280px) {
    .modal__body {
      width: 450px; } }
