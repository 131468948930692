.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  background: rgba(255, 255, 255, 0.7); }
  .loader svg {
    position: absolute;
    width: auto;
    height: 13rem;
    top: 250px;
    left: 50%;
    transform: translate(-50%, -50%); }
    .loader svg path {
      fill: rgba(0, 0, 0, 0);
      stroke: #b9b9b9;
      stroke-dashArray: 201;
      animation: stroke 3s infinite; }
  .loader.isHidden {
    display: none; }
  @media screen and (min-width: 40rem) {
    .loader svg {
      top: 350px; } }

@keyframes stroke {
  from {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: 400; } }
